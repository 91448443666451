const size = {
  mobile: "320px",
  mobileM: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const device = {
  mobile: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.tablet})`,
  tablet: `(max-width: ${size.laptop})`,
  laptop: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export default device;
