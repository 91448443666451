import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Layout from "./components/Layout";
import Loader from "./components/Loader";
import routes from "./config";
import "antd/dist/antd.css";
import GlobalStyle from "./globalStyles";

const HomePage = React.lazy(() => import("./pages/Home"));
const RoadmapPage = React.lazy(() => import("./pages/RoadMap"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const NewsUpdatesPage = React.lazy(() => import("./pages/NewsUpdates"));
const TermsConditions = React.lazy(() => import("./pages/TermsConditions"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Layout>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path={routes.HOME} component={HomePage} />
              <Route
                exact
                path={routes.NEWS_UPDATES}
                component={NewsUpdatesPage}
              />
              <Route exact path={routes.ROADMAP} component={RoadmapPage} />
              <Route exact path={routes.CONTACT} component={ContactPage} />
              <Route
                exact
                path={routes.PRIVACY_POLICY}
                component={PrivacyPolicy}
              />
              <Route
                path={routes.TERMS_CONDITIONS}
                component={TermsConditions}
              />
              <Route path={routes.NOT_FOUND} component={NotFound} />
              <Redirect from="*" to={routes.NOT_FOUND} />
            </Switch>
          </Suspense>
        </Layout>
      </Router>
    </>
  );
}

export default App;
