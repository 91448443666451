import styled from "styled-components";
import device from "../../../utils/devices";

export const Button = styled.a`
  display: block;
  width: 60%;
  position: relative;
  & > img {
    width: 18px;
    height: 18px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 45%;
    right: 0px;
    transform: translateY(-50%) rotate(45deg);
    border: ${(props) => (props.bg ? "solid #000" : "solid #fff")};
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  @media ${device.tablet} {
    width: auto;
    &:after {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 60px;
  margin-left: 40px;
`;

export const DropdownWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 70px;
  left: 5%;
  width: 267px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  padding: 15px 20px 5px;
  z-index: 2;
`;

export const DesktopDropdown = styled(Dropdown)`
  display: none;
  top: 40px;
  left: -28px;
  ${Wrapper}:hover & {
    display: block;
  }
`;

export const AppLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 25px 10px 15px;
  height: 100%;
  width: 100%;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  > * {
    &:last-child {
      border: none;
    }
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  height: 74px;
  border-bottom: 1px solid #dee8f4;
`;

export const AppName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  color: #3d557c;
`;

export const App = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: #102e5e;
  text-transform: uppercase;
`;

export const ImageWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`;
