import React, { useState } from "react";
import { useMedia } from "react-use";

import { mediaQueries, DESKTOP } from "../../../utils/mediaQueries";
import { apps } from "../data";

import * as Styled from "./AppMenu.styled";
import app from "../../../assets/apps-icon.svg";
import darkApp from "../../../assets/apps-icon-dark.svg";

const AppMenu = ({ bg }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMedia(mediaQueries[DESKTOP]);
  return (
    <>
      {isDesktop ? (
        <Styled.Wrapper>
          <Styled.Button bg={bg}>
            <img src={bg ? darkApp : app} alt="app-icon" />
          </Styled.Button>
          <Styled.DesktopDropdown>
            <Styled.List>
              {apps.map((ap) => (
                <Styled.ListItem key={ap.id}>
                  <Styled.AppLink
                    target="_blank"
                    rel="noreferrer"
                    href={ap.link}
                  >
                    <Styled.AppName>
                      <Styled.Title>Konstellation</Styled.Title>
                      <Styled.App>{ap.name}</Styled.App>
                    </Styled.AppName>
                    <Styled.ImageWrapper>
                      <img src={ap.img} alt="arrow-icon" />
                    </Styled.ImageWrapper>
                  </Styled.AppLink>
                </Styled.ListItem>
              ))}
            </Styled.List>
          </Styled.DesktopDropdown>
        </Styled.Wrapper>
      ) : (
        <>
          <Styled.Button bg={bg} onClick={() => setOpen((prev) => !prev)}>
            <img src={bg ? darkApp : app} alt="app-icon" />
          </Styled.Button>
          {open && (
            <Styled.DropdownWrapper onClick={() => setOpen(false)}>
              <Styled.Dropdown>
                <Styled.List>
                  {apps.map((ap) => (
                    <Styled.ListItem key={ap.id}>
                      <Styled.AppLink
                        target="_blank"
                        rel="noreferrer"
                        href={ap.link}
                      >
                        <Styled.AppName>
                          <Styled.Title>Konstellation</Styled.Title>
                          <Styled.App>{ap.name}</Styled.App>
                        </Styled.AppName>
                        <Styled.ImageWrapper>
                          <img src={ap.img} alt="arrow-icon" />
                        </Styled.ImageWrapper>
                      </Styled.AppLink>
                    </Styled.ListItem>
                  ))}
                </Styled.List>
              </Styled.Dropdown>
            </Styled.DropdownWrapper>
          )}
        </>
      )}
    </>
  );
};

export default AppMenu;
