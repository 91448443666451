import React, { useState, useEffect } from "react";
import { useMedia } from "react-use";

import { mediaQueries, DESKTOP } from "../../utils/mediaQueries";
import routes from "../../config";
import { docs, company } from "./data";
import MobileHeaderMenu from "./MobileHeaderMenu";
import AppMenu from "./AppMenuMobile";

import * as Styled from "./Header.styled";
import darkLogo from "../../assets/dark-logo.svg";
import lightLogo from "../../assets/light-logo.svg";

const Header = () => {
  const isDesktop = useMedia(mediaQueries[DESKTOP]);
  const [bg, setBg] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 90) {
      if (!bg) {
        setBg(true);
      }
    } else {
      setBg(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!isDesktop ? (
        <MobileHeaderMenu bg={bg} />
      ) : (
        <Styled.Header id="main-header" bg={bg}>
          <Styled.Container>
            <Styled.Logo>
              <Styled.LogoLink to={routes.HOME}>
                <img src={bg ? darkLogo : lightLogo} alt="logo" />
                <Styled.LogoTitle bg={bg}>Konstellation</Styled.LogoTitle>
              </Styled.LogoLink>
              <AppMenu bg={bg} />
            </Styled.Logo>

            <Styled.Nav bg={bg}>
              <Styled.Link exact to={routes.HOME}>
                Home
              </Styled.Link>
              <Styled.Link to={routes.ROADMAP}>Roadmap</Styled.Link>
              <Styled.Link to={routes.NEWS_UPDATES}>News & Updates</Styled.Link>

              {/* <Styled.DropdownWrapper>
                <Styled.NavButton bg={bg}>Ecosystem</Styled.NavButton>
                <Styled.Dropdown>
                  <Styled.List>
                    {darcLinks.map((link) => (
                      <Styled.ListItem key={link.id}>
                        <Styled.DropdownLink
                          target="_blank"
                          rel="noreferrer"
                          disabled={!link.link}
                          href={link.link}
                        >
                          <Styled.Name>{link.name}</Styled.Name>
                          {link.type && <Styled.Type>{link.type}</Styled.Type>}
                          {link.img && <Styled.Img src={link.img} alt="icon" />}
                        </Styled.DropdownLink>
                      </Styled.ListItem>
                    ))}
                  </Styled.List>
                </Styled.Dropdown>
              </Styled.DropdownWrapper> */}

              <Styled.DropdownWrapper>
                <Styled.NavButton bg={bg}>Docs</Styled.NavButton>
                <Styled.Dropdown>
                  <Styled.List>
                    {docs.map((doc) => (
                      <Styled.ListItem key={doc.id}>
                        <Styled.DropdownLink
                          target="_blank"
                          rel="noreferrer"
                          href={doc.url}
                        >
                          <Styled.Name>{doc.name}</Styled.Name>
                          <Styled.Img src={doc.img} alt="icon" />
                        </Styled.DropdownLink>
                      </Styled.ListItem>
                    ))}
                  </Styled.List>
                </Styled.Dropdown>
              </Styled.DropdownWrapper>
              <Styled.DropdownWrapper>
                <Styled.NavButton bg={bg}>Company</Styled.NavButton>
                <Styled.Dropdown>
                  <Styled.List>
                    {company.map((c) => (
                      <Styled.ListItem key={c.id}>
                        <Styled.DropdownLink
                          target="_blank"
                          rel="noreferrer"
                          href={c.url}
                        >
                          <Styled.Name>{c.name}</Styled.Name>
                          <Styled.Img src={c.img} alt="icon" />
                        </Styled.DropdownLink>
                      </Styled.ListItem>
                    ))}
                    <Styled.ListItem>
                      <Styled.Link to={routes.CONTACT}>Contact</Styled.Link>
                    </Styled.ListItem>
                  </Styled.List>
                </Styled.Dropdown>
              </Styled.DropdownWrapper>
            </Styled.Nav>
          </Styled.Container>
        </Styled.Header>
      )}
    </>
  );
};

export default Header;
