import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
  }
  
  html {
    height: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
  }
  
  body {
    height: 100%;
    font-family: 'Josefin Sans', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }
  
  ul {
    padding: 0;
    list-style: none;
  }

  button, input, textarea {
    font-family: 'Josefin Sans', sans-serif;
  }

  a {
    text-decoration: none;
    &: hover, active {
      color: #fff;
    }
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  
  select:focus, input:focus{
    outline: none;
  }

  *::selection {
    color: #ffffff;
}
  
`;

export default GlobalStyle;
