const routes = {
  HOME: "/",
  CONTACT: "/contact",
  NEWS_UPDATES: "/news-updates",
  ROADMAP: "/roadmap",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  NOT_FOUND: "/not-found",
};

export default routes;
