import bridge from "../../assets/bridge-icon.svg";
import wallet from "../../assets/wallet-icon.svg";
import explorer from "../../assets/explorer-icon.svg";
import goApp from "../../assets/go-app-icon.svg";

export const darcLinks = [
  {
    id: "vega",
    name: "VegaX Holdings",
    link: "https://vegaxholdings.com/",
    type: "",
    img: goApp,
  },
  {
    id: "taebit",
    name: "Taebit",
    link: "https://taebit.io/",
    type: "",
    img: goApp,
  },
  {
    id: "alkemy",
    name: "Alkemy",
    link: "https://alkemymint.io/",
    type: "",
    img: goApp,
  },
];

export const apps = [
  {
    id: 1,
    name: "Explorer",
    link: "https://explorer.konstellation.tech/",
    img: explorer,
  },
  {
    id: 2,
    name: "Bridge",
    link: "https://bridge.konstellation.tech/",
    img: bridge,
  },
  {
    id: 3,
    name: "Hubble (Web Wallet)",
    link: "https://wallet.konstellation.tech/",
    img: wallet,
  },
];

export const docs = [
  {
    id: "whitepaper",
    name: "Whitepaper",
    url:
      "https://konstellation.gitbook.io/konstellation-official-document/introduction/whitepaper",
    img: goApp,
  },
  {
    id: "doc",
    name: "Documentation",
    url: "https://konstellation.gitbook.io/konstellation-official-document/",
    img: goApp,
  },
];

export const company = [
  {
    id: "career",
    name: "Career",
    url:
      "https://konstellation.notion.site/konstellation/Careers-at-Kontellation-English-aab08b68be0545f5ba5441ce947bc01f",
    img: goApp,
  },
  {
    id: "media",
    name: "Media Press",
    url:
      "https://www.figma.com/proto/TwyfrYoRMYBmsy0WKefSNh/Design-System---Konstellation?page-id=1878%3A4&node-id=1878%3A5&viewport=242%2C48%2C0.25&scaling=contain&starting-point-node-id=1878%3A5",
    img: goApp,
  },
];
