export const SCREEN_DESKTOP = 1024;
export const SCREEN_TABLET = 767;

export const DESKTOP = "desktop";
export const TABLET = "tablet";
export const MOBILE = "mobile";

export const mediaQueries = {
  [DESKTOP]: `(min-width: ${SCREEN_DESKTOP}px)`,
  [TABLET]: `(min-width: ${SCREEN_TABLET}px) and (max-width: ${SCREEN_DESKTOP}px)`,
  [MOBILE]: `(max-width: ${SCREEN_TABLET}px)`,
};
