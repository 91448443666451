import styled, { css } from "styled-components";
import { NavLink, Link as RouterLink } from "react-router-dom";
import device from "../../utils/devices";

export const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  background: ${(props) => (props.bg ? "#ffffff" : "transparent")};
  z-index: 40;
  height: 90px;
  box-shadow: ${(props) =>
    props.bg ? "0px 1px 15px rgba(0, 0, 0, 0.15)" : "none"};

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @media ${device.laptop} {
    width: 95%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 60%;
  color: ${(props) => (props.bg ? "#000000" : "#ffffff")};
  & > a.active {
    color: ${(props) => (props.bg ? "#267CC0" : "#9ED5F3")};
    font-weight: 700;
    position: relative;
    &: after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 0;
      width: 21px;
      height: 1px;
      background-color: ${(props) => (props.bg ? "#267CC0" : "#9ED5F3")};
    }
  }
`;

export const Link = styled(NavLink)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 0.05em;
  color: inherit;
  &:hover,
  &:active {
    color: inherit;
  }
`;

export const DropdownLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const Name = styled.p`
  background: linear-gradient(180deg, #010101 0%, #21315c 54.17%);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 48px;
  letter-spacing: 0.05em;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 6px;

  @media ${device.tablet} {
    background: #fff;
    -webkit-text-fill-color: #999999;
  }
`;

export const DownloadLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 0.05em;
  color: inherit;
  &:hover,
  &:active {
    color: inherit;
  }
`;
export const LogoTitle = styled.p`
  text-transform: uppercase;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2em;
  color: ${(props) => (props.bg ? "#000000" : "#ffffff")};
`;

export const LogoLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  justify-content: start
  width: 160px;
  height: 50px;
  & > img {
    display: block;
    margin: 0 12px 0 0;
  }

  @media ${device.tablet} {
    width: 200px;
    & > img {
      margin: 0;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 10%;
    right: 80px;
    width: 1px;
    height: 80%;
    background: #3d7bc2;
  }
`;

export const NavButton = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 48px;
  letter-spacing: 0.05em;
  color: inherit;
  position: relative;
  display: inline-block;
  width: auto;

  & > img {
    width: 18px;
    height: 18px;
  }
  &:hover,
  &:active {
    color: inherit;
  }
  &:after {
    content: "";
    position: absolute;
    top: 45%;
    right: -15px;
    transform: translateY(-50%) rotate(45deg);
    border: ${(props) => (props.bg ? "solid #000" : "solid #fff")};
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }
  @media ${device.tablet} {
    color: #999999;
    &:after {
      top: 40%;
      transform: ${(props) =>
        props.open ? "rotate(-135deg)" : "rotate(45deg)"};
      border: solid #999999;
      border-width: 0 1px 1px 0;
    }

    &:hover,
    &:active {
      color: #999999;
    }
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
`;

export const Dropdown = styled.div`
  display: none;
  width: 200px;
  padding: 12px 18px 4px;
  position: absolute;
  z-index: 15;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  top: 40px;
  left: 50%;
  transform: translateX(-50%);

  ${DropdownWrapper}:hover & {
    display: block;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  > * {
    &:last-child {
      border: none;
    }
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  border-bottom: 1px solid #dee8f4;
  a {
    color: #21305a;
    font-size: 13px;
  }
  @media ${device.tablet} {
    padding-left: 28px;
    border-bottom: none;
    height: auto;
    a {
      color: none;
      background: #fff;
      -webkit-text-fill-color: #999999;
    }
  }
`;

export const Type = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 48px;
  text-align: right;
  color: #707070;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HamburgerButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.1;
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const ModalMenu = styled.div`
  width: 100vw;
  background-color: #fff;
  display: block;
  position: fixed;
  z-index: 10;
  right: 0;
  top: 0;
  padding: 20px 20px 130px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(-100%)"};
  transition: all 0.4s ease-in;
`;

export const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  color: #999999;
`;

export const MobileLink = styled(Link)`
  &.active {
    color: #000000;
  }
`;

export const Img = styled.img`
  display: block;
  width: 16px;
  height: 14px;
`;
