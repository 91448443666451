import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import routes from "../../config";
import { docs, company } from "./data";
import AppMenu from "./AppMenuMobile";

import * as Styled from "./Header.styled";
import darkLogo from "../../assets/mobile-dark-logo.svg";
import lightLogo from "../../assets/mobile-light-logo.svg";
import darkMenu from "../../assets/dark-menu-icon.svg";
import lightMenu from "../../assets/light-menu-icon.svg";
import cross from "../../assets/cross.svg";

const MobileHeaderMenu = ({ bg }) => {
  const [open, setOpen] = useState(false);
  const [openEcos, setOpenEcos] = useState(false);
  const [openDocs, setOpenDocs] = useState(false);
  const [openCompany, setOpenCompany] = useState(false);
  const location = useLocation();

  const isOnHome = location.pathname === routes.HOME;

  useEffect(() => {
    if (open) {
      setOpen(false);
    }

    if (openEcos) {
      setOpenEcos(false);
    }

    if (openDocs) {
      setOpenDocs(false);
    }

    if (openCompany) {
      setOpenCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const closeMenuHandler = () => {
    setOpen(false);
    setOpenEcos(false);
    setOpenDocs(false);
    setOpenCompany(false);
  };

  return (
    <Styled.Header id="main-header" bg={bg}>
      <Styled.Container>
        <AppMenu bg={bg} />
        {!bg ? (
          <>
            {!isOnHome && (
              <Styled.LogoLink to={routes.HOME}>
                <img src={lightLogo} alt="logo" />
              </Styled.LogoLink>
            )}
            <Styled.HamburgerButton onClick={() => setOpen(true)}>
              <img src={lightMenu} alt="menu" />
            </Styled.HamburgerButton>
          </>
        ) : (
          <>
            <Styled.LogoLink to={routes.HOME}>
              <img src={darkLogo} alt="logo" />
            </Styled.LogoLink>
            <Styled.HamburgerButton onClick={() => setOpen(true)}>
              <img src={darkMenu} alt="menu" />
            </Styled.HamburgerButton>
          </>
        )}
        {open && <Styled.Backdrop onClick={closeMenuHandler} />}
        <Styled.ModalMenu isOpen={open}>
          <Styled.Wrapper>
            <Styled.LogoLink to={routes.HOME}>
              <img src={darkLogo} alt="logo" />
            </Styled.LogoLink>
            <Styled.HamburgerButton onClick={closeMenuHandler}>
              <img src={cross} alt="cross" />
            </Styled.HamburgerButton>
          </Styled.Wrapper>
          <Styled.MobileNav>
            <Styled.MobileLink exact to={routes.HOME}>
              Home
            </Styled.MobileLink>
            <Styled.MobileLink to={routes.ROADMAP}>Roadmap</Styled.MobileLink>
            <Styled.MobileLink to={routes.NEWS_UPDATES}>
              News & Updates
            </Styled.MobileLink>
            {/* <Styled.NavButton
              open={openEcos}
              onClick={() => setOpenEcos((prev) => !prev)}
            >
              Ecosystem
            </Styled.NavButton>
            {openEcos && (
              <Styled.List>
                {darcLinks.map((link) => (
                  <Styled.ListItem key={link.id}>
                    <Styled.DropdownLink
                      target="_blank"
                      rel="noreferrer"
                      disabled={!link.link}
                      href={link.link}
                    >
                      <Styled.Name>{link.name}</Styled.Name>
                      {link.type && <Styled.Type>{link.type}</Styled.Type>}
                    </Styled.DropdownLink>
                  </Styled.ListItem>
                ))}
              </Styled.List>
            )} */}
            <Styled.NavButton
              open={openDocs}
              onClick={() => setOpenDocs((prev) => !prev)}
            >
              Docs
            </Styled.NavButton>
            {openDocs && (
              <Styled.List>
                {docs.map((doc) => (
                  <Styled.ListItem key={doc.id}>
                    <Styled.DropdownLink
                      target="_blank"
                      rel="noreferrer"
                      href={doc.url}
                    >
                      <Styled.Name>{doc.name}</Styled.Name>
                    </Styled.DropdownLink>
                  </Styled.ListItem>
                ))}
              </Styled.List>
            )}
            <Styled.NavButton
              open={openCompany}
              onClick={() => setOpenCompany((prev) => !prev)}
            >
              Company
            </Styled.NavButton>
            {openCompany && (
              <Styled.List>
                {company.map((c) => (
                  <Styled.ListItem key={c.id}>
                    <Styled.DropdownLink
                      target="_blank"
                      rel="noreferrer"
                      href={c.url}
                    >
                      <Styled.Name>{c.name}</Styled.Name>
                    </Styled.DropdownLink>
                  </Styled.ListItem>
                ))}
                <Styled.ListItem>
                  <Styled.MobileLink to={routes.CONTACT}>
                    Contact
                  </Styled.MobileLink>
                </Styled.ListItem>
              </Styled.List>
            )}
          </Styled.MobileNav>
        </Styled.ModalMenu>
      </Styled.Container>
    </Styled.Header>
  );
};

export default MobileHeaderMenu;
